 
        .car {
            font-family: Poppins, sans-serif;
            background-color: #d4ecef;
            margin: 0;
            padding: 10px;
        }

        .container-career {
            width: 90%;
            max-width: 800px;
            margin: 50px auto;
            background-color: #fff;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }

        .container-career h1 {
            text-align: center;
            color: #333;
            font-size: 3rem;
        }

        .container-career p {
            text-align: center;
            color: #555;
            font-size: 1.1em;
        }

        .form-group {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
        }

        .form-group label {
            display: block;
            color: #333;
            margin-bottom: 5px;
            font-size: 0.9em;
        }

        .form-group input[type="text"],
        .form-group input[type="email"],
        .form-group input[type="number"],
        .form-group input[type="date"],
        .form-group select,
        .form-group textarea {
            width: 48%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 0.9em;
        }

        .form-group.full {
            width: 100%;
        }

        .form-group.full input,
        .form-group.full textarea,
        .form-group.full select {
            width: 100%;
        }

        .form-group textarea {
            height: 100px;
            resize: vertical;
        }

        .form-group input[type="file"] {
            padding: 5px;
        }

        .form-group .small-inputs {
            display: flex;
            justify-content: space-between;
        }

        .form-group .small-inputs input {
            width: 48%;
        }

        .form-group input[type="submit"] {
            width: 100%;
            padding: 15px;
            background-color: #f18f16;
            border: none;
            color: white;
            font-size: 1em;
            cursor: pointer;
            border-radius: 5px;
        }

        .form-group input[type="submit"]:hover {
            background-color: #d27f1a;
        }

        .note {
            font-size: 0.85em;
            color: #999;
        }

        .required {
            color: red;
        }
        .integrity{
            padding-top: 3%;
            font-size: 3.2rem;
            font-weight: 800;
            text-align: center;
        }