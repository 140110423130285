/* Import your font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0.600;0,700;0,800;0,900&display=swap');

body {
  margin: 0;
  font-family: Poppins, sans-serif;
}

/* Footer styles */
.footer {
  display: flex; /* Make the footer a flex container */
  justify-content: space-between; /* Align items horizontally with space between */
  align-items: center; /* Align items vertically in the center */
  background-color: #057588; /* Set the background color */
  padding: 20px 30px; /* Set padding for content */
  width: 100%; /* Ensure the footer takes up the full screen width */
}

.logo {
  align-items: center; /* Center logo content vertically */
  text-align: center; /* Center logo content horizontally */
  margin-left: auto; /* Align logo to the left of the container */
  margin-right: auto; /* Align logo to the right of the container */
  margin-top: 3%; /* Add some top margin */
}

.partitions {
  align-items: flex-start; /* Align content within each partition to the top */
  text-align: left; /* Align text within each partition to the left */
  /* No margin needed for partitions */
}

.lst-elem {
  padding: 1%;
  text-wrap: balance;
}

.links {
  cursor: pointer;
  margin-bottom: 3%;
  text-align: left;
  color: white;
  font-weight: 300;
  list-style: none;
  transition: all 300ms ease-in-out;
}

.main-title {
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: left;
}

.section-2 {
  display: flex; /* Make the section-2 a flex container */
  flex-wrap: wrap; /* Allow items to wrap if they don't fit on one line */
  width: 100%; /* Ensure the section-2 takes up the full screen width */
  margin-left: 0;
  gap: 4%; /* Maintain a gap between partitions */
}

.links:hover {
  color: #f18f16;
}