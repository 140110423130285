@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    font-family: Poppins, sans-serif;
    background: #e69e47;
    /* background-color: #121; */
}

.gall-head{
    font-size: 3.4rem;
    text-align: center;
    padding-top: 2%;
    color: #fff;
    font-weight: 700;
    /* background: #000; */
}

section.horizontal {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    position: relative;
}

section.horizontal .pin-wrap,
section.horizontal .animation-wrap {
    display: flex;
    position: relative;
    z-index: 1;
    height: 100vh;
}

section.horizontal .animation-wrap {
    gap: 5em;
    padding: 5em;
}

section.horizontal .item {
    position: relative;
    padding: 0px;
    /* margin: 20px 20px; */
    /* flex: 0 0 400px; */
    height: calc(100vh - 10em);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.7;
    user-select: none;
    width: max-content;
    border-radius: 36px;
}

.item img {
    border-radius: 9px;
    filter: drop-shadow(2px 4px 6px black);
}

@media only screen and (max-width:500px) {

    section.horizontal .pin-wrap,
    section.horizontal .animation-wrap {
        flex-direction: column;
        height: inherit;
    }

    section.horizontal .item {
        justify-content: center;
        margin: 0 !important;
    }
}