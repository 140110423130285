* {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        body {
            height: 100svh;
            overflow-x: hidden;
            background-color: #fff;
            color: #e7e7e7;
            margin: 0;
            font-family: Poppins, sans-serif;
        }

        section {
            height: 100%;
        }

        .container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: 710px;
        }

        h3 {
            font-size: 30px;
            margin-bottom: 20px;
            font-weight: 600;
            color: #f18f16;
        }

        p {
            font-size: 18px;
            font-weight: 600;
            color: #057588;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        a.zoomed p {
            -webkit-line-clamp: unset;
        }

        a.zoomed .card-inner {
            overflow: auto;
        }

        a.opacity-0 {
            opacity: 0;
        }

        .overflow {
            overflow: hidden;
        }

        .overflow .background {
            opacity: 0 !important;
        }

        .background {
            background-color: #f18f16;
            border-radius: 20px;
            position: absolute;
            transition: all 0.4s ease-in-out;
            opacity: 0;
        }

        .card-wraper {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            justify-content: space-between;
        }

        a.card {
            text-decoration: none;
            height: 100%;
            width: 100%;
            display: inline-block;
            position: relative;
            padding: 10px;
        }

        .card-main {
            border: 1px solid #f18f16;
            padding: 40px;
            width: 100%;
            height: 100%;
            min-height: 300px;
            border-radius: 20px;
            overflow: hidden;
            background-color: rgb(253, 248, 242);
            transition: all 400ms ease-in-out;
        }

        .card:hover .card-main {
            position: relative;
            z-index: 20;
        }

        .card-inner {
            position: relative;
            z-index: 50;
            height: 100%;
        }

        .card-inner div {
            height: 100%;
        }

        img {
            height: 100%;
            width: 100%;
        }

        @media screen and (max-width: 1199px) {
            .container {
                max-width: 960px;
            }
        }

        @media screen and (max-width: 991px) {
            body {
                height: 100%;
            }

            .container {
                max-width: 720px;
                display: block;
            }

            .card-wraper {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }

        @media screen and (max-width: 767px) {
            .container {
                max-width: 520px;
            }

            .card-wraper {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            .card-main {
                padding: 30px 20px;
            }

            a.card {
                padding: 6px;
            }
        }

        @media screen and (max-width: 575px) {
            .container {
                max-width: 100%;
            }

            .card-wraper {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }

            .card-main {
                padding: 40px;
            }

            a.card {
                padding: 10px;
            }
        }
        .serv-main{
            font-size: 4.2rem;
            font-weight: 600;
            color: #057588;
            text-align: center;
            margin-bottom: 4%;
        }