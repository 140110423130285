@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

body {
    margin: 0;
    font-family: Poppins, sans-serif;
}
.logo_nav{
    
    
    width: auto;
    height: auto;

}
.navbar {
    display: flex;
    gap: 9%;
    background-color: #057588;
    padding: 25px 20px;
}

.nav-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav {
    display: flex;
    justify-content: space-between;
    list-style: none;
    gap: 5%;
    margin: 0;
    padding: 0;
    margin-left: 20%;
}

.links_nav {
    cursor: pointer;
    padding: 5%;
    text-align: center;
    white-space: nowrap;   
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;
    transition: all 300ms ease-in-out;
}
.links_nav a{
    text-decoration: none;
    color:inherit; 
}
.links_nav:hover{
    color: #f18f16;
}

.contact-us {
  padding: 10px 20px;
  background-color: #f18f16;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    white-space: nowrap;  
    transition: all 400ms ease-in-out;
}
.contact-us:hover{
    background-color: #d67f14;
}